.videoCard {
    margin-bottom: 40px;
    width: 270px
}

.videoCard__thumbnail {
    height: 140px;
    width: 250px;
}

.recommendedVideos__videos {
    display: flex;
    flex-wrap: wrap;
}

.videoCard__info {
    display: flex;
    margin-top: 10px;
    padding-right: 30px;
}

.videoCard__text {
    margin-left: 15px;
}

.videoCard__text > h4 {
    font-size: 14px;
    margin-bottom: 5px;
}

.videoCard__text > p {
    font-size: 14px;
    color: gray;
}

.video__avatar {
    height: 30px !important;
    width: 30px !important;
}