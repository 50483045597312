.aboutmain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #f9f9f9;
    padding: 20px 20px;
}

.name{
    background-color: #f9f9f9;
}
.age{
    margin: 15px;
    margin-left: 30px;
    background-color: #f9f9f9;
}

.break {
    flex-basis: 100%;
    height: 0;
}
.about{
    margin: 20px;
    max-width: 800px;
    background-color: rgb(212, 209, 214);
    border-radius: 9px;
    box-shadow: 4px 4px 15px 9px rgba(212,209,214,0.94);
}
.foto1{
    max-width: 800px;
}
.fotoniki{
    margin-top: 20px;
    height: 200px;
    object-fit: contain;
    border-radius: 50%;
    background-color: rgb(212, 209, 214);
    box-shadow: 4px 4px 15px 9px rgba(212,209,214,0.94);
}
.foto2{
    max-width: 1500px;
}
.fotoschool{
    margin: 20px;
    height: 200px;
    object-fit: contain;
    border-radius: 10%;
    background-color: rgb(212, 209, 214);
    box-shadow: 4px 4px 15px 9px rgba(212,209,214,0.94);
}
.school{
    margin-top: 50px;
    margin-left: 50px;
    max-height: 100px;
    object-fit: contain;
    border-radius: 10%;
    background-color: rgb(212, 209, 214);
    box-shadow: 4px 4px 15px 9px rgba(212,209,214,0.94);
}
/*-----------------------*/
.webdev{
    margin: 20px;
    border-radius: 10%;
    background-color: rgb(212, 209, 214);
    box-shadow: 4px 4px 15px 9px rgba(212,209,214,0.94);
}
.robotics{
    margin: 20px;
    border-radius: 10%;
    background-color: rgb(212, 209, 214);
    box-shadow: 4px 4px 15px 9px rgba(212,209,214,0.94);
}
.design{
    margin: 20px;
    border-radius: 10%;
    background-color: rgb(212, 209, 214);
    box-shadow: 4px 4px 15px 9px rgba(212,209,214,0.94);
}
/*-----------------------*/
.other{
    margin: 20px;
    border-radius: 10%;
    background-color: rgb(212, 209, 214);
    box-shadow: 4px 4px 15px 9px rgba(212,209,214,0.94);
}