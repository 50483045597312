.sidebar{
    flex: 0.2;
}

.sidebar > hr {
    height: 1px;
    border: 0;
    background-color: lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}
a {
    color: black;
    text-decoration: none;
  }