.searchPage {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 20px 20px;
}

.searchPage > hr {
    height: 1px;
    border: 0;
    background-color: lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gitlink button{
    background-color: lightgray;
    border-radius: 9px;
    padding: 10px;
    margin-left: 10px;
}


.gitlink button:hover{
    background-color: rgb(173, 169, 169);
    cursor: pointer;
}