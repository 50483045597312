.game {
  flex: 0.8;
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  padding: 20px 20px;
}

.game > hr {
  height: 1px;
  border: 0;
  background-color: lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
}
  .snake-container {
    margin-left: 50px;
    align-items: top;
    justify-content: center;
  }
  .instruction {
    align-items: left;
    justify-content: center;
  }
  .instruction p{
    align-items: left;
    justify-content: center;

  }
  
  .grid {
    width: 500px;
    height: 500px;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-color: black;
  }
  
  .grid-item {
    outline: 1px solid grey;
    width: 50px;
    height: 50px;
  }
  
  .snake {
    outline: 1px solid blue;
    background-color: blue;
    width: 50px;
    height: 50px;
  }
  
  .food {
    outline: 1px solid yellow;
    background-color: yellow;
    width: 50px;
    height: 50px;
  }

