.recommendedVideos {
    flex: 0.8;
    background-color: #f9f9f9;
    padding: 40px 20px;
    padding-bottom: 0;
}

.recommendedVideos > h2 {
    margin-left: 5px;
    margin-bottom: 20px;
}